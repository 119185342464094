<template>
  <div id="OrderImage" class="pb-8">
    <min-nav></min-nav>
    <page-header title="صورة الطلب" icon="fas fa-file-image"></page-header>
    <div class="add-form -mt-10 bg-white w-11/12 mx-auto py-4 rounded shadow mb-3">
      <div class="container">
        <form action="" class="grid grid-cols-1 lg:grid-cols-1 gap-3 relative">
          <get-loader class="absolute inset-0 z-10"></get-loader>
          <div class="img-section mb-3">
            <div class="upload">
              <input type="file" @change="getImage($event)" accept="image/png, image/jpeg" />
              <i class="fas fa-cloud-upload-alt text-4xl"></i>
              <span class="text-xl underline"> رفع صورة الطلب </span>
            </div>
            <div class="preview">
              <div class="img-title flex-center flex-col" v-if="showImg == ''">
                <i class="fas fa-photo-video text-4xl"></i>
                <span class="text-xl font-bold requier"> عرض صورة الطلب </span>
              </div>
              <img :src="showImg" v-else class="h-60 object-cover" alt="" @click="openModal(showImg)" />
            </div>
          </div>
          <div class="order_image_controll flex justify-end">
            <button :class="[
              postLoader ? 'opacity-60 cursor-not-allowed' : '',
              'btn main-btn ',
            ]" v-if="order_image !== ''" :disabled="postLoader" @click.prevent="uploadOrderImage()">
              رفع صورة الطلب
            </button>
          </div>
          <div class="img_viwer  grid grid-cols-4 justify-stretch gap-3">
            <div class="imga-show border-2 border-gray-200 border-dashed p-2 relative" v-for="(img, index) in imgs"
              :key="img.size">
              <!-- if the modal are open then the remove button should be hidden -->
              <span v-if="!modalVisible" @click="removrImage(img, index)"
                class="w-6 h-6 flex items-center justify-center text-white z-30 rounded-full bg-red-500 absolute top-0 right-0 cursor-pointer">
                <i class="fas fa-times"></i>
              </span>
              <img :src="img.url" class="w-full h-full object-contain" style="width: 400%;" alt="" @click="openModal(img.url)" />
            </div>
            <div v-if="imgs.length < 5"
              class="relative w-full h-60 border-2 border-gray-200 border-dashed flex items-center justify-center">
              <input type="file" @change="getImgs($event)" maxlength="5" multiple accept=".jpg, .jpeg, .png"
                class="absolute inset-0 w-full h-full cursor-pointer opacity-0" />
              <span class="text-xl flex flex-col gap-3 items-center justify-center">
                <i class="far fa-images text-5xl text-gray-200"></i>
                <span class="text-lg text-gray-700 font-bold">
                  رفع صور أضافيه
                </span>
              </span>
            </div>
          </div>
          <div class="controll flex justify-end col-span-full">
            <button :class="[
              postLoader ? 'opacity-60 cursor-not-allowed' : '',
              'btn main-btn',
            ]" :disabled="postLoader" v-if="imgs.length !== 0" @click.prevent="add_new_item()">
              رفع الصور الاضافية
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- the edit model -->
    <div v-show="modalVisible" class="
          absolute
          inset-0
          flex
          items-center
          justify-center
          bg-gray-700 bg-opacity-50
        ">
      <div class="max-w-2xl p-6 mx-4 bg-white rounded-md shadow-xl">
        <div class="flex items-center justify-between">
          <h3 class="text-2xl">Edit Image</h3>
          <svg @click="closeModal" xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-red-900 cursor-pointer"
            fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>
        <div class="mt-4">
          <img :src="modalImage" alt="Uploaded Image" class="modal-image" ref="imgRef" @load="initializeMarkerJs" />
          <button @click="closeModal" class="px-6 py-2 ml-2 text-blue-100 bg-blue-600 rounded">
            Save
          </button>
        </div>
      </div>
    </div>
    <router-link v-if="checkOrderIsCompleted()" :to="'/print/' + $route.params.id">
      <div
        class="print-btn w-16 h-16 rounded-full bg-main text-white text-2xl flex items-center justify-center fixed bottom-5 left-5 print:hidden cursor-pointer animate-bounce"
        @click="printPage()">
        <i class="fas fa-print"></i>
      </div>
    </router-link>

  </div>
</template>
<style>
header {
  border-radius: 0 0 50% 50%;
}
</style>
<script>
import * as markerjs2 from 'markerjs2';
export default {
  data() {
    return {
      imgs: [], // Each item should be { id: string, file: File, annotatedBlob: Blob, type: string, url: string }
      showImg: "",
      order_image: "",
      postData: {
        order_id: "",
        description: "",
      },
      modalVisible: false, // Control modal visibility
      modalImage: "", // Store the image source for the modal
    };
  },
  methods: {
    // Open the modal with the uploaded image
    openModal(imageSrc) {
      // check if the images are old (not new image) then don't opent the edit modal
      // the modal opens only with the newly uploaded image
      let count = 0;
      for (let item in this.order.images) {
        if (this.order.images[item].image != imageSrc) {
          count++;
        }
      }
      if (count == this.order.images.length) {
        this.modalVisible = true;
        this.modalImage = imageSrc;
        this.modalImageObj = this.imgs.find(img => img.url === imageSrc);
        // If there's no annotatedBlob, create an empty one
        if (!this.modalImageObj.annotatedBlob) {
          this.modalImageObj.annotatedBlob = new Blob();
        }
      }
    },
    checkOrderIsCompleted() {
      if(this.order.is_completed === 1) {
        return true;
      }
      return false;
    },
    // Initialize Marker.js
    initializeMarkerJs() {
      const markerArea = new markerjs2.MarkerArea(this.$refs.imgRef);
      // make the markerArea Colors as the used color by the client
      markerArea.settings.defaultColorSet = ['#be113c', '#d946ef', '#6366f1', '#a755f7', '#e9b308', '#6b7380', '#3b82f6', '#000000', '#f97216', '#157f3d', '#23c55e'];
      markerArea.addEventListener('render', event => {
        this.modalImageObj.annotatedBlob = this.dataURLtoBlob(event.dataUrl);
        this.imgs.find((img) => {
          if (img.url == this.modalImage) {
            img.url = URL.createObjectURL(img.annotatedBlob)
          }
        });
        this.modalImage = event.dataUrl; // Update modalImage with annotated image
      });
      markerArea.show();
    },
    // close Modal method (after saving)
    closeModal() {
      this.modalVisible = false;
      this.modalImage = "";
    },
    getImage(event) {
      this.order_image = event.target.files[0];
      this.showImg = URL.createObjectURL(event.target.files[0]);
    },
    getImgs(event) {
      Object.values(event.target.files).forEach((el) => {
        const size = 2000000;
        if (this.imgs.length < 5) {
          if (el.size < size) {
            this.imgs.push({
              id: "",
              file: el,
              type: "front",
              url: URL.createObjectURL(el),
            });
          }
        }
      });
    },
    uploadOrderImage() {
      this.uploadImage({
        id: this.$route.params.id,
        image: this.dataReutrn({
          image: this.order_image,
          _method: "PUT",
        }),
      })
    },
    changeImgs(event, index) {
      this.imgs[index] = {
        file: event.target.files[0],
        type: "front",
        url: URL.createObjectURL(event.target.files[0]),
      };
    },
    removrImage(img, index) {
      if (img.type == "front") {
        this.imgs.splice(index, 1);
      } else {
        this.confirmDelete(() => {
          this.removeImage(img.id).then(() => {
            this.imgs.splice(index, 1);
          });
        });
      }
    },
    add_new_item() {
      this.imgs.forEach((el, index) => {
        if (el.type === 'front') {
          if (el.annotatedBlob) {
            this.postData[`images[${index}]`] = el.annotatedBlob; // Upload annotated image blob
          }
          else {
            this.postData[`images[${index}]`] = el.file; // Upload original image file
          }
        }
      });
      this.createOrderImage(this.dataReutrn(this.postData)).then(() => {
        // call the getOrder method to check all the uploaded images and not edit them again
        this.imgs = [];
        this.getOrder({
          id: this.$route.params.id,
          include: "images",
        }).then(() => {
          if (this.order.image_url !== "" || this.order.image_url !== null) {
            this.showImg = this.order.image_url;
          }
          this.order.images.forEach((image) => {
            this.imgs.push({
              id: image.id,
              file: "",
              type: "back",
              url: image.image,
            });
          });
        });
      });
    },
    // adjust the annotated image url to blob (Binary large object)
    dataURLtoBlob(dataURL) {
      const arr = dataURL.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
  },
  mounted() {
    this.postData.order_id = this.$route.params.id;
    this.checkOrderIsCompleted();
    this.getOrder({
      id: this.$route.params.id,
      include: "images",
    }).then(() => {
      if (this.order.image_url !== "" || this.order.image_url !== null) {
        this.showImg = this.order.image_url;
      }
      this.order.images.forEach((image) => {
        this.imgs.push({
          id: image.id,
          file: "",
          type: "back",
          url: image.image,
        });
      });
    });
  },
};
</script>
