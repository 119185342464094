<template>
  <div id="OrderPage" class="pb-8">
    <min-nav></min-nav>
    <page-header title="الطلبات" icon="fas fa-users"></page-header>
    <div v-if="chackOwner || this.getUserRole == 'admin'" :class="[changeStatusInit ? 'active' : '', 'edit-form']">
      <form action=""
        class="grid grid-cols-1 gap-1 w-11/12 md:w-8/12 bg-white rounded px-8 py-5 max-h-full overflow-auto">
        <div class="input mb-4">
          <label for="" class="requier"> قسم الفحص </label>
          <select name="" id="" v-model="changeStatusValue.status" class="field">
            <option value="" selected disabled>القسم</option>
            <option value="1">جـــك</option>
            <option value="2">داينو</option>
            <option value="3">فحص البدي</option>
            <option value="6">كمبيوتر</option>
          </select>
        </div>

        <div class="controll flex justify-end pt-8">
          <button class="btn sub-btn" @click.prevent="changeStatusInit = false">
            غلق
          </button>
          <button :class="[
            postLoader ? 'opacity-60 cursor-not-allowed' : '',
            'btn main-btn',
          ]" :disabled="postLoader" @click.prevent="changeStatusData()">
            تعديل الحالة
          </button>
        </div>
      </form>
    </div>
    <div v-if="(chackOwner||chackAdmin)" :class="[editStatus ? 'active' : '', 'edit-form']">
      <form action=""
        class="grid grid-cols-2 lg:grid-cols-3 gap-1 w-11/12 md:w-8/12 bg-white rounded px-3 py-5 max-h-full overflow-auto">
        <div class="input">
          <label for="" class=""> أسم السائق </label>
          <input type="text" placeholder="أسم السائق" v-model="editData.driver_name" class="field" />
        </div>
        <div class="input">
          <label for="" class="requier"> رقم الهاتف </label>
          <input type="text" placeholder="رقم الهاتف" v-model="editData.driver_phone" class="field" />
        </div>
        <div class="input mb-4">
          <label for="" class="requier"> رقم السيارة </label>
          <input type="text" placeholder="رقم السيارة" v-model="editData.car_no" class="field" />
        </div>

        <div class="input mb-4">
          <label for="" class="requier"> نوع السيارة </label>
          <input type="text" placeholder="نوع السيارة" v-model="editData.car_type" class="field" />
        </div>

        <div class="input mb-4">
          <label for="" class="requier"> حجم السيارة </label>
          <select name="" id="" v-model="editData.car_body_type" class="field">
            <option value="" selected disabled>حجم السيارة</option>
            <option value="0">صالون</option>
            <option value="1">بيكم</option>
            <option value="2">فان</option>
            <option value="3">SUV</option>
          </select>
        </div>

        <div class="input mb-4">
          <label for="" class="requier"> لون السيارة </label>
          <input type="text" placeholder="لون السيارة" v-model="editData.car_color" class="field" />
        </div>

        <div class="input mb-4">
          <label for="" class="requier"> موديل السيارة </label>
          <input type="text" placeholder="موديل السيارة" v-model="editData.car_model" class="field" />
        </div>
        <div class="input mb-4">
          <label for="" class="requier"> رقم السنوية </label>
          <input type="text" placeholder="رقم السنوية" v-model="editData.sanoia_no" class="field" />
        </div>
        <div class="input mb-4">
          <label for="" class="requier"> رقم الشاصي </label>
          <input type="text" placeholder="رقم الشاصي" v-model="editData.chassis_no" class="field" />
        </div>

        <div class="input mb-4">
          <label for="" class="requier"> عداد السرعة </label>
          <input type="text" placeholder="عداد السرعة" v-model="editData.speedometer" class="field" />
        </div>

        <div class="input mb-4">
          <label for="" class="requier"> السعر </label>
          <input type="text" placeholder="السعر" v-model="editData.price" class="field" />
        </div>

        <div class="input mb-4">
          <label for="" class="requier"> الخصم </label>
          <input type="text" placeholder="الخصم" v-model="editData.discount" class="field" />
        </div>

        <div class="controll flex justify-end pt-8 col-span-full">
          <button class="btn sub-btn" @click.prevent="toggleEditStatus()">
            غلق
          </button>
          <button :class="[
            postLoader ? 'opacity-60 cursor-not-allowed' : '',
            'btn main-btn',
          ]" :disabled="postLoader" @click="edit_item()">
            تعديل
          </button>
        </div>
      </form>
    </div>
    <div v-if="chackOwner || this.getUserRole == 'admin'" class="add-form -mt-10 bg-white w-11/12 mx-auto py-4 rounded shadow mb-3">
      <div class="container">
        <div class="section-title">
          <span class="text-xl">أضافة طلب</span>
        </div>
        <form action="" class="grid grid-cols-2 lg:grid-cols-4 gap-2">
          <div class="input">
            <label for="" class=""> أسم السائق </label>
            <input type="text" placeholder="أسم السائق" v-model="postData.driver_name" class="field" />
          </div>
          <div class="input">
            <label for="" class="requier"> رقم الهاتف </label>
            <input type="text" placeholder="رقم الهاتف" v-model="postData.driver_phone" class="field" />
          </div>
          <div class="input mb-4">
            <label for="" class="requier"> رقم السيارة </label>
            <input type="text" placeholder="رقم السيارة" v-model="postData.car_no" class="field" />
          </div>

          <div class="input mb-4">
            <label for="" class="requier"> نوع السيارة </label>
            <input type="text" placeholder="نوع السيارة" v-model="postData.car_type" class="field" />
          </div>

          <div class="input mb-4">
            <label for="" class="requier"> حجم السيارة </label>
            <select name="" id="" v-model="postData.car_body_type" class="field">
              <option value="" selected disabled>حجم السيارة</option>
              <option value="0">صالون</option>
              <option value="1">بيكم</option>
              <option value="2">فان</option>
              <option value="3">SUV</option>
            </select>
          </div>

          <div class="input mb-4">
            <label for="" class="requier"> لون السيارة </label>
            <input type="text" placeholder="لون السيارة" v-model="postData.car_color" class="field" />
          </div>

          <div class="input mb-4">
            <label for="" class="requier"> موديل السيارة </label>
            <input type="text" placeholder="موديل السيارة" v-model="postData.car_model" class="field" />
          </div>
          <div class="input mb-4">
            <label for="" class="requier"> رقم السنوية </label>
            <input type="text" placeholder="رقم السنوية" v-model="postData.sanoia_no" class="field" />
          </div>
          <div class="input mb-4">
            <label for="" class="requier"> رقم الشاصي </label>
            <div class="relative flex">
              <input type="search" placeholder="رقم الشاصي" class="field pr-16 rounded-r-none"
                v-model="postData.chassis_no" />
              <span @click="filteringChassie()"
                class="absolute inset-y-0 left-0 flex items-center px-2 text-gray-100 cursor-pointer bg-blue-500 rounded-l">
                <i class="fas fa-search"></i>
              </span>
            </div>
            <p class="relative flex" style="color: green; font-weight: 600;"
              v-if="postData.chassis_no && chassis_filter && chassis_result_count > 0">
              * تم فحصها ({{ chassis_result_count }}) مرة

            </p>
            <p style="color: red; font-weight: 600;"
              v-if="postData.chassis_no && chassis_filter && chassis_result_count === 0">
              * لم يتم فحصها سابقاً
            </p>
          </div>

          <div class="input mb-4">
            <label for="" class="requier"> عداد السرعة </label>
            <input type="text" placeholder="عداد السرعة" v-model="postData.speedometer" class="field" />
          </div>

          <div class="input mb-4">
            <label for="" class="requier"> قسم الفحص </label>
            <select name="" id="" v-model="postData.status" class="field">
              <option value="" selected disabled>القسم</option>
              <option value="1">جـــك</option>
              <option value="2">داينو</option>
              <option value="3">فحص البدي</option>
              <option value="6">كمبيوتر</option>
            </select>
          </div>

          <div class="input mb-4">
            <label for="" class="requier"> السعر </label>
            <input type="text" placeholder="السعر" v-model="postData.price" class="field" />
          </div>

          <div class="input mb-4">
            <label for="" class="requier"> الخصم </label>
            <input type="text" placeholder="الخصم" v-model="postData.discount" class="field" />
          </div>

          <div class="controll flex justify-end col-span-full">
            <button :class="[
              postLoader ? 'opacity-60 cursor-not-allowed' : '',
              'btn main-btn',
            ]" :disabled="postLoader" @click.prevent="add_new_item()">
              أضافة
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="list-form">
      <div class="container">
        <div class="section-title">
          <span class="text-xl">قائمة الطلبات</span>
          <div class="show-per-Page text-sm font-normal">
            <span class=""> عناصر القائمة </span>
            <select name="" id="" v-model="filterData.take" class="bg-gray-100 px-2 rounded-md outline-none"
              @change="filtering">
              <option value="2">2</option>
              <option value="15">15</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
            </select>
          </div>
        </div>

        <div class="filters">
          <div class="name input">
            <input type="search" @change="filtering()" placeholder="اسم السائق" class="field" v-model="filterData.name" />
          </div>
          <div class="name input">
            <input type="search" @change="filtering()" placeholder="رقم السنوية" class="field"
              v-model="filterData.sanoia_no" />
          </div>
          <div class="name input">
            <input type="search" @change="filtering()" placeholder="رقم الشاصي" class="field"
              v-model="filterData.chassis_no" />
          </div>
          <div class="status input">
            <select name="" id="" class="field" @change="filterOrderStatus()" v-model="filterOrderStatusvalue">
              <option value="" selected disabled>حالة الطلب</option>
              <option value="">الكل</option>
              <option :value="5">ملغا</option>
              <option :value="4">مكتمل</option>
            </select>
          </div>
          <div v-if="chackOwner" class="status input">
            <select name="" id="" class="field" @change="filtering()" v-model="filterData.creator_id">
              <option value="" selected disabled>اسم المستخدم</option>
              <option :value="item.id" v-for="item, index in usersList" :key="index">{{ item.name }}</option>
            </select>
          </div>
        </div>
        <div class="list relative py-5">
          <get-loader v-if="!chassis_result_count && !chassis_filter && !postData.chassis_no" class="absolute inset-0"></get-loader>
          <data-table :fields="fields" :data="orders.data" :actions="chackOwner|| this.getUserRole == 'admin'">
            <template v-slot:order_no="{ item }">
              {{ item.order_no?.split("-")[4] }}
            </template>
            <template v-slot:created_at="{ item }">
              {{ getFormDate(item.created_at) }}
            </template>
            <template v-slot:status="{ item }">
              <span :class="orderStatus(item.status).cla" class="border px-2 py-1 rounded-md w-22 text-center block">
                {{ orderStatus(item.status).text }}
              </span>
            </template>
            <template v-slot:updated_at="{ item }">
              {{ getFormDate(item.updated_at) }}
            </template>
            <template v-slot:actions="{ item }">
              <ul class="actions-list">
                <li @click="showChangeStatus(item)" aria-label="نقل الى قسم"
                  class="text-gray-500 hover:text-purple-500 hint--top">
                  <i class="fas fa-bezier-curve"></i>
                </li>
                <li @click="add_img_form(item.id)" aria-label="اضافة صور"
                  class="text-gray-500 hover:text-purple-500 hint--top">
                  <i class="fas fa-file-image"></i>
                </li>
                <li aria-label="طباعة فاتورة" v-if="item.is_completed == 1" class="text-gray-500 hover:text-purple-500 hint--top">
                  <router-link :to="'/print/' + item.id">
                    <i class="fas fa-print"></i>
                  </router-link>
                </li>
                <li v-if="item.status !== 4 && item.status !== 5 && ( chackOwner || chackAdmin ) " aria-label="تعديل الطلب"
                  class="text-gray-500 hover:text-blue-500 hint--top" @click="editInit(item)">
                  <i class="fas fa-pencil-alt"></i>
                </li>
                <li v-if="item.status !== 4 && item.status !== 5 && ( chackOwner || chackAdmin )" aria-label="أكمال الطلب"
                  class="text-gray-500 hover:text-green-500 hint--top" @click="complete_order(item)">
                  <i class="fas fa-clipboard-check"></i>
                </li>
                <li v-if="item.status !== 4  && item.is_completed == 0 && item.status !== 5 && ( chackOwner || chackAdmin )" aria-label="الغاء الطلب"
                  class="text-gray-500 hover:text-red-500 hint--top" @click="cancele_order(item)">
                  <i class="fas fa-times"></i>
                </li>
                <!-- <li
                  class="text-gray-500 hover:text-red-500"
                  @click="remove_item(item)"
                >
                  <i class="fas fa-trash-alt"></i>
                </li> -->
              </ul>
            </template>
          </data-table>
        </div>
      </div>
    </div>
    <div class="pagination w-11/12 mx-auto py-4 bg-white mt-3" v-if="orders.total > filterData.take">
      <div class="container flex items-center justify-center">
        <v-pagination v-model="currentPage" :records="orders.total" :per-page="filterData.take" />
      </div>
    </div>
  </div>
</template>
<style>
header {
  border-radius: 0 0 50% 50%;
}
</style>
<script>
import $http from "@/plugins/axios.js"
export default {
  data() {
    return {
      currentPage: 1,
      changeStatusInit: false,
      filterOrderStatusvalue: "",
      carTypes: ["Kia", "Hunday", "Tayouta"],
      postData: {
        driver_name: "",
        driver_phone: "",
        car_no: "",
        car_type: "",
        car_color: "",
        car_body_type: "",
        car_model: "",
        sanoia_no: "",
        chassis_no: "",
        speedometer: "",
        price: "",
        discount: 0,
        status: "",
      },
      usersList:[],

      changeStatusValue: {
        status: "",
      },
      editData: {},
      tempData: {},
      filterData: {
        take: 15,
        page: 1,
        name: "",
        chassis_no: "",
        sanoia_no: "",
        creator_id: "",
      },
      chassis_filter: false,
      chassis_result_count: 0
    };
  },
  computed: {
    fields() {
      return [
        {
          title: "رقم الفاتورة",
          key: "order_no",
        },
        {
          title: "تاريخ",
          key: "created_at",
        },
        {
          title: "أسم السائق",
          key: "driver_name",
        },
        {
          title: "نوع السيارة",
          key: "car_type",
        },
        {
          title: "لون السيارة",
          key: "car_color",
        },
        {
          title: "السعر",
          key: "price",
        },
        {
          title: "خصم",
          key: "discount",
        },
        {
          title: "السعر الكلي",
          key: "total_price",
        },
        {
          title: "حالة الطلب",
          key: "status",
        },
        {
          title: "أخر تعديل",
          key: "updated_at",
        },
      ];
    },
    chackOwner() {
      if (this.getUserRole == "Owner") {
        return true;
      } else {
        return false;
      }
    },
    chackAdmin() {
      if (this.getUserRole == "admin") {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    currentPage(value) {
      this.filterData.page = value;
      this.getDayleOrders(this.filterData);
    },
  },
  methods: {
    filtering() {
      this.getDayleOrders(this.filterData)
    },
    filteringChassie() {
      $http.get(`/orders-today?filter[chassis_no]=${this.postData.chassis_no}&take=1000`).then((res) => {
        this.chassis_result_count = res.data.total;
        this.chassis_filter = true;
      });
    },
    filterOrderStatus() {
      if (this.filterOrderStatusvalue == "") {
        this.getOrders();
      } 
      else if (this.filterOrderStatusvalue == 4) {
        this.getCompletedOrders();
      } else {
        this.getCancelleddOrders();
      }
    },
    add_new_item() {
      this.createOrder(this.postData).then(() => {
        this.postData = {
          driver_name: "",
          driver_phone: "",
          car_no: "",
          car_type: "",
          car_body_type: "",
          car_color: "",
          car_model: "",
          sanoia_no: "",
          chassis_no: "",
          speedometer: "",
          price: "",
          discount: 0,
          status: '',
        };
      });
    },
    editInit(item) {
      this.toggleEditStatus();
      Object.assign(this.editData, item);
      this.editData._method = "PUT";
    },
    edit_item() {
      delete this.editData.last_login;
      this.updateOrder(this.editData).then(() => {
        this.toggleEditStatus();
      });
    },
    add_img_form(id) {
      this.$router.push(`/order_image/${id}`);
    },
    cancele_order(item) {
      this.confirmDelete(() => {
        this.canceleOrder(item.id);
      });
    },
    complete_order(item) {
      this.confirmDelete(() => {
        this.completeOrder(item.id);
      });
    },
    showChangeStatus(item) {
      this.changeStatusInit = true;
      this.changeStatusValue.order_id = item.id;
    },
    changeStatusData() {
      this.changeStatusOrder({
        order_id: this.changeStatusValue.order_id,
        data: this.dataReutrn({
          status: this.changeStatusValue.status,
          _method: "PUT",
        }),
      }).then(() => {
        this.changeStatusInit = false;
        this.getDayleOrders();
      });
    },
  },
  mounted() {
    if(this.chackOwner){
      this.getUsers().then(()=>{
        this.usersList = this.users.data;
      })  
    }
    this.getDayleOrders();
    document.querySelectorAll("#OrderPage .field").forEach((el) => {
      el.addEventListener("input", () => {
        if (el.value.length !== 0) {
          el.classList.add("filled");
        } else {
          el.classList.remove("filled");
        }
      });
    });
  },
};
</script>
